import { defineStore } from "pinia";
import { GaAccount, GaAccountResponse } from "~/types/GaAccount";

export const useGaAccountStore = defineStore({
  id: "ga-account-store",
  state: () => {
    return {
      gaAccounts: [],
    } as {
      gaAccounts: Array<GaAccount>;
    };
  },
  actions: {
    async listGaAccounts() {
      const { data, error } = await useDatAdsApiFetch<{
        data: { gaAccounts: Array<GaAccountResponse> };
      }>("gaaccount");
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedGaAccounts } = useGaAccounts();
        this.gaAccounts = getMappedGaAccounts(data.value.data.gaAccounts);
      }
      return this.gaAccounts;
    },

    async updatGaAccounts(
      gaAccounts: Array<{
        gaAccountId: number;
        clientId?: number | null | undefined;
        isMapped?: boolean | undefined | null;
        loadLifetimeData?: boolean | undefined | null;
      }>,
    ) {
      // Do an optimistic update i.e. update internal state first even, if API operation is not successful
      // This helps flickering of UI during API call
      for (const gaAccount of gaAccounts) {
        const index = this.gaAccounts.findIndex(
          (a) => a.id === gaAccount.gaAccountId,
        );
        if (index !== -1) {
          this.gaAccounts[index] = {
            ...this.gaAccounts[index],
            deleted: false,
            clientId:
              typeof gaAccount.clientId === "number" ||
              gaAccount.clientId === null
                ? gaAccount.clientId
                : this.gaAccounts[index].clientId,
          };
        }
      }
      const { error } = await useDatAdsApiFetch<{
        data: { gaAccounts: Array<GaAccountResponse> };
      }>("gaaccount", {
        method: "PATCH",
        body: [...gaAccounts],
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async deleteGaAccount(gaAccountId: number) {
      const { error } = await useDatAdsApiFetch(`gaaccount/${gaAccountId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.gaAccounts = this.gaAccounts.filter(
        (gaAccount) => gaAccount.id !== gaAccountId,
      );
      return null;
    },
  },
  getters: {
    getGaAccountById:
      (state) =>
      (gaAccountId: number): GaAccount | null => {
        const acc = state.gaAccounts.find(
          (gaAccount) => gaAccount.id === gaAccountId,
        );
        return acc || null;
      },

    notDeletedGaAccounts: (state) =>
      state.gaAccounts.filter((gaAccount) => !gaAccount.deleted),

    deletedGaAccounts: (state) =>
      state.gaAccounts.filter((gaAccount) => gaAccount.deleted),
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGaAccountStore, import.meta.hot));
}
