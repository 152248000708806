import { GaAccount, GaAccountResponse } from "~/types/GaAccount";

export const useGaAccounts = () => {
  const getMappedGaAccounts = (
    gaAccounts: Array<GaAccountResponse>,
  ): Array<GaAccount> => {
    const clientStore = useClientStore();
    const { getProviderLogo, providerToDisplayName } = useConnection();

    const mappedGaAccounts = gaAccounts.map<GaAccount>((gaAccount) => {
      const client = clientStore.getClientById(gaAccount.clientId);

      return {
        ...gaAccount,
        logo: getProviderLogo(gaAccount.provider),
        displayName: gaAccount.name,
        providerDisplayName: providerToDisplayName(gaAccount.provider),
        client,
        clientDisplayName: client != null ? client.displayName : "---",
        deleted:
          typeof gaAccount.deleted === "boolean" ? gaAccount.deleted : false,
      };
    });

    mappedGaAccounts.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedGaAccounts;
  };

  return { getMappedGaAccounts };
};
